import React, { useEffect, useLayoutEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import "./applePayStyles.css";

export default function ApplePay() {
  const [loading, setLoading] = useState(true);
  const [checkoutId, setCheckoutId] = useState(null);
  const [uid, setUid] = useState({});

  let location = useLocation();

  useLayoutEffect(() => {
    const setupCheckout = async () => {
      const parsed = await queryString.parse(location.search);
      const data = parsed.data;
      const id = parsed.uid;
      await setCheckoutId(data);
      await setUid(id);
      await setLoading(false);
    };
    setupCheckout();
  }, [location.search]);

  // useEffect(() => {
  //   // getCheckoutId({});
  //   window.addEventListener("message", function (data) {
  //     try {
  //       const val = JSON.parse(data.data);
  //       if (val.from === "fr_APC") {
  //         getCheckoutId(val);
  //         setVal(val);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   });
  //   return () => {
  //     window.removeEventListener("message", function (data) {
  //       console.log(data.data);
  //     });
  //   };
  // }, []);

  // const getCheckoutId = (data) => {
  //   if (data?.orderId) {
  //     // alert(val?.orderId);
  //     // const uid = Math.random().toString(36).substring(5);
  //     // setVal({ ...val, uid: uid });
  //     axios({
  //       url: "https://us-central1-fitting-room-3d548.cloudfunctions.net/prepareApplePayUI",
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //       // data: {
  //       //   merchantTransactionId: data.orderId,
  //       //   amount: data.bill.grandTotal,
  //       // },
  //       data: {
  //         merchantTransactionId: Math.random().toString(36).substring(5),
  //         amount: 149,
  //       },
  //     })
  //       .then((res) => {
  //         setCheckoutId(res.data.id);
  //         setLoading(false);
  //         window.webkit?.messageHandlers?.ReactNativeWebView?.postMessage(
  //           "gettingData2!"
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         alert(err);
  //       });
  //   }
  // };

  const renderPaymentform = () => {
    console.log("Loading Form");
    const script = document.createElement("script");
    script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    // script.src = `https://test.oppwa.com/v1/checkouts/${checkoutId}/registration`;
    script.async = true;
    script.locale = "en";
    document.body.appendChild(script);
    const form = document.createElement("form");
    form.action = `https://payments.fittingroom.store/paymentResult?uid=${uid}&applePay=true`;
    // form.action = `https://payments.fittingroom.store/paymentResult?uid="val.uid"`;
    window.wpwlOptions = {
      applePay: {
        merchantCapabilities: ["supports3DS"],
        countryCode: ["SA"],
        supportedNetworks: ["masterCard", "visa", "mada"],
        displayName: "FittingRoom",
        total: { label: "FittingRoom Store" },
        style: "black",
        onCancel: (c) => {
          console.log("Cancelled", JSON.stringify(c));
          window.webkit?.messageHandlers?.ReactNativeWebView?.postMessage(
            "canceled!"
          );
        },
        onPaymentAuthorized: (a) => {
          console.log("Authorized", JSON.stringify(a));
          const string = JSON.stringify({
            type: "success!",
          });
          window.webkit?.messageHandlers?.ReactNativeWebView?.postMessage(
            string
          );
          window?.ReactNativeWebView?.postMessage(string);
        },
      },
      // billingAddress: {},
      // mandatoryBillingFields: {
      //   country: true,
      //   state: true,
      //   city: true,
      //   postcode: true,
      //   street1: true,
      //   street2: false,
      // },
    };
    form.setAttribute("class", "paymentWidgets");
    form.setAttribute("data-brands", "APPLEPAY");
    document.body.appendChild(form);
  };

  if (loading)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4 style={{ textAlign: "center" }}>Loading Apple Pay..</h4>
      </div>
    );

  return <div>{renderPaymentform()}</div>;
}
