import React, { useLayoutEffect, useState } from "react";
// import axios from "axios";
// import "./style.css";
import frWhite from "../../Assets/fittingroom_white.png";
// import frBlack from "../../Assets/fittingroom_black.jpg";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export default function CheckoutV2() {
  const [loading, setLoading] = useState(true);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [checkoutId, setCheckoutId] = useState(null);
  const [uid, setUid] = useState(null);
  const [bill, setBill] = useState({});

  let location = useLocation();

  useLayoutEffect(() => {
    const setupCheckout = async () => {
      const parsed = await queryString.parse(location.search);
      const data = parsed.data;
      const id = parsed.uid;
      const subTotal = parsed.subTotal;
      const taxAmount = parsed.taxAmount;
      const shipping = parsed.shipping;
      const grandTotal = parsed.grandTotal;
      const paymentBrand = parsed.paymentBrand;
      await setCheckoutId(data);
      await setUid(id);
      await setBill({
        subTotal,
        taxAmount,
        shipping,
        grandTotal,
        paymentBrand,
      });
      console.log(parsed);
      await setLoading(false);
    };
    setupCheckout();
  }, [location.search]);

  const renderPaymentForm = () => {
    if (!loading && !window?.wpwlOptions) {
      console.log("Loading Form");
      const script = document.createElement("script");
      script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      // script.src = `https://test.oppwa.com/v1/checkouts/${checkoutId}/registration`;
      script.async = true;
      script.locale = "en";
      script.onload = async () => {
        console.log("loaded");
        setScriptLoaded(true);
      };
      // script.onerror = () => {
      //   console.log("error");
      // };
      document.body.appendChild(script);
      const form = document.createElement("form");
      form.action = `https://payments.fittingroom.store/paymentResultProd?uid=${uid}`;
      window.wpwlOptions = {
        style: "plain",
        customer: {},
        billingAddress: {},
        mandatoryBillingFields: {
          country: true,
          state: true,
          city: true,
          postcode: true,
          street1: true,
          street2: false,
          email: true,
        },
        maskCvv: true,
        registrations: { requireCvv: true, hideInitialPaymentForms: true },
        onReady: function () {
          var $ = document.getElementById;
          var createRegistrationHtml =
            '<div class="customLabel">Store payment details?</div><div class="customInput"><input type="checkbox" name="createRegistration" value="true" /></div>';
          $("form.wpwl-form-card")
            .find(".wpwl-button")
            .before(createRegistrationHtml);
        },
      };
      form.setAttribute("class", "paymentWidgets");
      form.setAttribute("data-brands", "MADA VISA MASTER");
      document.body.appendChild(form);
    }
  };

  if (loading)
    return (
      <div style={{ marginTop: 20 }}>
        <h3 style={{ textAlign: "center" }}>Loading Payment Form</h3>
      </div>
    );

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        marginBottom: 20,
        marginTop: 10,
      }}
    >
      <div style={{ width: "100%" }}>
        <img
          src={frWhite}
          width="150"
          style={{ display: "block", margin: "auto" }}
          alt="FittingRoom Logo"
        />
        <h3
          style={{ marginBottom: 20, textAlign: "center", fontWeight: "bold" }}
        >
          Complete Checkout
        </h3>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ fontWeight: "normal", margin: 0, padding: 5 }}>
            Subtotal :
          </h4>
          <h4 style={{ fontWeight: "normal", margin: 0, padding: 5 }}>
            SR {bill?.subTotal}
          </h4>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ fontWeight: "normal", margin: 0, padding: 5 }}>Vat :</h4>
          <h4 style={{ fontWeight: "normal", margin: 0, padding: 5 }}>
            SR {bill?.taxAmount}
          </h4>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ fontWeight: "normal", margin: 0, padding: 5 }}>
            Shipping :{" "}
          </h4>
          <h4 style={{ fontWeight: "normal", margin: 0, padding: 5 }}>
            SR {bill?.shipping}
          </h4>
        </div> */}
        <hr />
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <h3 style={{ fontWeight: "bold" }}>Total Price : </h3>
          <h3 style={{ fontWeight: "bold" }}>SR {bill?.grandTotal}</h3>
        </div> */}
      </div>
      {!scriptLoaded ? (
        <div style={{ marginTop: 10 }}>
          <h3 style={{ textAlign: "center" }}>Loading Payment Form..</h3>
        </div>
      ) : null}
      {renderPaymentForm()}
    </div>
  );
}
