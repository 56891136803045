import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddCard from "./Screens/AddCard";
// import Checkout from "./Screens/Checkout";
import CheckoutV2 from "./Screens/CheckoutV2";
import Result from "./Screens/Result";
import PaymentResult from "./Screens/PaymentResult";
import ApplePay from "./Screens/ApplePay";
// import ApplePayV2 from "./Screens/ApplePayV2";

//Production
// import AddCard from "./Screens/AddCard";
// import CheckoutV2 from "./Screens/CheckoutV2";
import ResultProd from "./Screens/Prod/ResultProd";
import PaymentResultProd from "./Screens/Prod/PaymentResultProd";
import ApplePayProd from "./Screens/Prod/ApplePayProd";
import AddCardProd from "./Screens/Prod/AddCardProd";
import CheckoutV2Prod from "./Screens/Prod/CheckoutV2Prod";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Tests Paths */}
        <Route path="/" element={<AddCard />} />
        <Route path="/result" element={<Result />} />
        <Route path="/checkoutV2" element={<CheckoutV2 />} />
        <Route path="/paymentResult" element={<PaymentResult />} />
        <Route path="/applePay" element={<ApplePay />} />

        {/* Production Paths */}
        <Route path="/addCardProd" element={<AddCardProd />} />
        <Route path="/resultProd" element={<ResultProd />} />
        <Route path="/checkoutV2Prod" element={<CheckoutV2Prod />} />
        <Route path="/paymentResultProd" element={<PaymentResultProd />} />
        <Route path="/applePayProd" element={<ApplePayProd />} />

        {/* Not Used */}
        {/* <Route path="/checkout" element={<Checkout />} /> */}
        {/* <Route path="/applePayV2" element={<ApplePayV2 />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
