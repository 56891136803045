import React, { useEffect, useLayoutEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function PaymentResultProd() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [applePay, setApplePay] = useState(false);
  const [pid, setPid] = useState(null);
  let location = useLocation();

  useLayoutEffect(() => {
    const runFun = async () => {
      const parsed = await queryString.parse(location.search);
      const resourcePath = parsed.resourcePath;
      const id = parsed.id;
      const ap = parsed?.applePay ? true : false;
      setApplePay(ap);
      setPid(id);
      const string = JSON.stringify({
        type: "success!",
        id: id,
      });
      window.webkit?.messageHandlers?.ReactNativeWebView?.postMessage(string);
      window?.ReactNativeWebView?.postMessage(string);

      if (parsed?.applePay) {
        axios({
          url: "https://us-central1-fitting-room-3d548.cloudfunctions.net/checkUserPaymentProd",
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data: {
            uid: parsed?.uid,
            resourcePath: resourcePath,
            applePay: ap,
          },
        })
          .then((res) => {
            console.log(res);
            if (parsed?.applePay) {
              window.webkit?.messageHandlers?.ReactNativeWebView?.postMessage(
                string
              );
            }
            setData(res.data);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error, "error");
          });
      }
    };

    runFun();
  }, [location.search]);

  useLayoutEffect(() => {
    window.addEventListener("message", function (data) {
      try {
        const val = JSON.parse(data.data);
        if (val.from === "fr_SAC") {
          setData(val.data);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    });
    return () => {
      window.removeEventListener("message", function (data) {
        console.log(data.data);
      });
    };
  }, []);

  const checkResult = () => {
    const successPattern = /^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/;
    const manuallPattern = /^(000\.400\.0[^3]|000\.400\.100)/;

    const match1 = successPattern.test(data.result.code);
    const match2 = manuallPattern.test(data.result.code);
    console.log(match1, match2);
    const string = JSON.stringify({
      type: "success!",
      id: pid,
    });
    if (match1 || match2) {
      window?.ReactNativeWebView?.postMessage(string);
      window.webkit?.messageHandlers?.ReactNativeWebView?.postMessage(string);
      window.close();
      return (
        <div>
          <h1>Success</h1>
          <h3>{data.result.description}</h3>
        </div>
      );
    } else {
      window?.ReactNativeWebView?.postMessage("failed!");
      window.webkit?.messageHandlers?.ReactNativeWebView?.postMessage(
        "failed!"
      );
      return (
        <div>
          <h1>Failed</h1>
          <h3>{data.result.description}</h3>
        </div>
      );
    }
  };

  if (!loading) {
    if (applePay) return null;
    return <div>{checkResult()}</div>;
  } else {
    return (
      <div>
        {applePay ? (
          <h4 style={{ textAlign: "center" }}>Getting Payment Status</h4>
        ) : (
          <h1>Please wait and don't close this window</h1>
        )}
      </div>
    );
  }
}
