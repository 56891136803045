import React, { useEffect, useLayoutEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function Result() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  let location = useLocation();

  useLayoutEffect(() => {
    const runFun = async () => {
      const parsed = await queryString.parse(location.search);
      console.log(parsed);
      const resourcePath = parsed.resourcePath;
      console.log(resourcePath);
      console.log(parsed.uid);
      axios({
        url: "https://us-central1-fitting-room-3d548.cloudfunctions.net/checkRegistration",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: {
          uid: parsed.uid,
          resourcePath: resourcePath,
        },
      })
        .then((res) => {
          console.log(res);
          setData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };
    runFun();
  }, [location.search]);

  const navBack = (fromWeb, status) => {
    if (fromWeb) {
      //run a function after 5 seconds
      setTimeout(() => {
        window.location.href =
          "http://localhost:3000/checkout?addPayment=true&status=" + status;
      }, 5000);
    }
  };

  const checkResult = async () => {
    const parsed = await queryString.parse(location.search);
    const fromWeb = parsed?.web;
    const successPattern = /^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/;
    const manuallPattern = /^(000\.400\.0[^3]|000\.400\.100)/;

    const match1 = successPattern.test(data.result.code);
    const match2 = manuallPattern.test(data.result.code);
    navBack(fromWeb, match1);
    console.log(match1, match2);
    if (match1 || match2) {
      return (
        <div>
          <h1>Success</h1>
          <h3>{data.result.description}</h3>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Failed</h1>
          <h3>{data.result.description}</h3>
        </div>
      );
    }
  };

  if (!loading) {
    return <div>{checkResult()}</div>;
  } else {
    return (
      <div>
        <h1>Loading</h1>
      </div>
    );
  }
}
