import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";

export default function AddCard() {
  const [loading, setLoading] = useState(true);
  const [checkoutId, setCheckoutId] = useState(null);
  const [val, setVal] = useState({});

  useLayoutEffect(() => {
    window.addEventListener("message", function (data) {
      try {
        const val = JSON.parse(data.data);
        if (val.from === "fr") {
          getCheckoutId(val);
          setVal(val);
          console.log(val);
        }
      } catch {}
    });
    return () => {
      window.removeEventListener("message", function (data) {
        console.log(data.data);
      });
    };
  }, []);

  const getCheckoutId = async (data) => {
    if (data?.merchantTransactionId && data?.uid) {
      await axios({
        url: "https://us-central1-fitting-room-3d548.cloudfunctions.net/prepareRegistrationV2",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: {
          merchantTransactionId: data.merchantTransactionId,
          uid: data.uid,
          email: data?.email,
          givenName: data?.name,
          surname: data?.lastName,
        },
      })
        .then(async (res) => {
          await setCheckoutId(res.data.id);
          await setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const renderPaymentform = () => {
    console.log("Loading Form");
    const script = document.createElement("script");
    script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    // script.src = `https://test.oppwa.com/v1/checkouts/${checkoutId}/registration`;
    script.async = true;
    script.locale = "en";
    window.wpwlOptions = {
      style: "plain",
      billingAddress: {},
      mandatoryBillingFields: {
        country: true,
        state: true,
        city: true,
        postcode: true,
        street1: true,
        street2: false,
      },
    };
    document.body.appendChild(script);
    const form = document.createElement("form");
    form.action = `https://payments.fittingroom.store/result?uid=${val.uid}`;
    form.setAttribute("class", "paymentWidgets");
    form.setAttribute("data-brands", "MADA VISA MASTER");
    document.body.appendChild(form);
  };

  if (loading)
    return (
      <div style={{ marginTop: 20 }}>
        <h3 style={{ textAlign: "center" }}>Loading Payment Form</h3>
      </div>
    );

  return (
    <div>
      <div style={{ marginTop: 20, marginBottom: 30 }}>
        <h3 style={{ textAlign: "center" }}>Add New Payment</h3>
      </div>
      {renderPaymentform()}
    </div>
  );
}
